import { Button, Table, Popconfirm, Drawer, message, Spin } from "antd";
import { useEffect, useState } from "react";
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { deleteMaterial, getMaterials } from "../../services/materials";
import './style.css';
import { MaterialForm } from "../materialForm";

export const Materials = () => {
  var [materials, setMaterials] = useState([]);
  var [isMaterialFormVisible, setMaterialFormVisibility] = useState(false);
  var [isLoading, setIsLoading] = useState(true);
  var [editingMaterial, setEditingMaterial] = useState({});
  const loadMaterials = () => {
    setIsLoading(true);
    getMaterials().then((res) => {
      res = res.map(loc => {
        loc.key = loc.id;
        return loc;
      });
      setMaterials(res);
      setIsLoading(false);
    }).catch(e => {
      message.error("Failed to load materials!");
      setIsLoading(false);
    });
  }
  useEffect(() => {
    loadMaterials();
  }, []);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "action",
      render: (id, row) => {
        return (
          <>
            <Popconfirm
              title="Are you sure to delete this material?"
              onConfirm={() => {
                setIsLoading(true);
                deleteMaterial(id).then(res => {
                  if (res.error) {
                    message.error("Failed to delete the material. Please try again ...");
                    setIsLoading(false);
                    return;
                  }
                  message.success("Material Deleted. Reloading the list ...");
                  loadMaterials();
                });
              }}
              onCancel={() => {}}
              okText="Yes"
              cancelText="No"
            >
              <Button icon={ <DeleteOutlined />}/>
            </Popconfirm>
            <Button icon={<EditOutlined />} onClick={() => {
              setEditingMaterial(row);
              setMaterialFormVisibility(true);
            }}/>
          </>
        );
      },
    },
  ];

  if (isLoading) {
    return <Spin size="large" />;
  }

  return (
    <>
      <Button type="primary" className="add-product-category-button" onClick={() => {
        setEditingMaterial({});
        setMaterialFormVisibility(true);
    }}>Add New</Button>
    <Table
      dataSource={materials}
      columns={columns}
      pagination={{
        total: materials.length,
        pageSize: 10,
        showSizeChanger: false,
      }}
      />
      <Drawer title="Add Material" placement="right" width={window.innerWidth / 4} onClose={() => {
        setEditingMaterial({});
        setMaterialFormVisibility(false);
      }} visible={isMaterialFormVisible}>
        {isMaterialFormVisible && <MaterialForm onDone={() => {
          setEditingMaterial({});
          setMaterialFormVisibility(false);
          loadMaterials();
        }} material={editingMaterial} />}
      </Drawer>
      </>
  );
};
