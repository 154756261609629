import { Form, Input, Button, message, Row, Col, Card } from "antd";
import { useHistory } from "react-router";
import { login } from "../../services/auth";
import "./style.css";
export const Login = () => {
    const {push} = useHistory();
  return (
    <Row>
      <Col span={8} offset={8} className="login-container">
        <Card className="login">
          <Form
            name="basic"
            labelCol={{
              span: 8,
            }}
            wrapperCol={{
              span: 16,
            }}
            initialValues={{
              username: "",
              password: "",
            }}
            onFinish={(value) => {
              login(value).then((res) => {
                if (res.error) {
                  message.error("Failed to login. Please try again ...");
                  return;
                }
                if (res.token) {
                  localStorage.setItem("token", res.token);
                  message.success(
                    "Login success. Redirecting to dashboard ..."
                  );
                  push("/dashboard");
                  return;
                }
                message.error("Failed to login. Please try again ...");
              });
            }}
            onFinishFailed={() => {
              message.error("Failed to login. Please try again ...");
            }}
            autoComplete="off"
          >
            <Form.Item
              label="Email"
              name="email"
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "Please input your email!",
                },
                {
                  type: "email",
                  message: "Email is not valid!",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Password"
              hasFeedback
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input your password!",
                },
                {
                  min: 8,
                  message: "Minimum Password length should be 8!",
                },
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item
              wrapperCol={{
                offset: 8,
                span: 16,
              }}
            >
              <Button type="primary" htmlType="submit">
                Login
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Col>
    </Row>
  );
};
