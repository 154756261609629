export const login = (credentials) => {
  credentials.device_name = Date.now();
  return fetch(process.env.REACT_APP_API_URL + "/api/login", {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    body: JSON.stringify(credentials), // body data type must match "Content-Type" header
  }).then((res) => res.json());
};
export const me = () => {
  return fetch(process.env.REACT_APP_API_URL + "/api/user", {
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    referrerPolicy: "no-referrer",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
      Accept: "application/json",
    },
  }).then((response) => response.json());
};

export const getUsers = (type, page) => {
  return fetch(process.env.REACT_APP_API_URL + `/api/users?type=${type}`, {
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    referrerPolicy: "no-referrer",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
      Accept: "application/json",
    },
  }).then((response) => response.json());
};

export const changeUser = (id, status) => {
  return fetch(process.env.REACT_APP_API_URL + "/api/user/update", {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + localStorage.getItem("token")
    },
    referrerPolicy: "no-referrer",
    body: JSON.stringify({
      id: id,
      status: status
    }),
  }).then((res) => res.json());
};
