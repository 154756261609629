import { Button, Table, Popconfirm, message, Spin, Radio } from "antd";
import { useEffect, useState } from "react";
import { EyeInvisibleOutlined,EyeOutlined } from '@ant-design/icons';
import './style.css';
import { changeUser, getUsers } from "../../services/auth";

export const UserList = () => {
  var [users, setUsers] = useState([]);
  var [userType, setUserType] = useState('2');
  var [isLoading, setIsLoading] = useState(true);
  const loadUsers = () => {
    setIsLoading(true);
    getUsers(userType).then((res) => {
      setUsers(res);
      setIsLoading(false);
    }).catch(e => {
      message.error("Failed to load users!");
      setIsLoading(false);
    });
  }
  useEffect(() => {
    loadUsers();
  }, [userType]);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "action",
      render: (id, data) => {
        return (
          <>
            {data.disabled === 0 && <Popconfirm
              title="Are you sure to disable this user?"
              onConfirm={() => {
                setIsLoading(true);
                changeUser(id, 1).then(res => {
                  if (res.error) {
                    message.error("Failed to disable. Please try again ...");
                    setIsLoading(false);
                    return;
                  }
                  message.success("User is disabled. Reloading the list ...");
                  loadUsers();
                });
              }}
              onCancel={() => { }}
              okText="Yes"
              cancelText="No"
            >
              <Button icon={<EyeInvisibleOutlined />} />
            </Popconfirm>}
            {data.disabled === 1 && <Popconfirm
              title="Are you sure to enable this user?"
              onConfirm={() => {
                setIsLoading(true);
                changeUser(id, 0).then(res => {
                  if (res.error) {
                    message.error("Failed to enable. Please try again ...");
                    setIsLoading(false);
                    return;
                  }
                  message.success("User is enableed. Reloading the list ...");
                  loadUsers();
                });
              }}
              onCancel={() => { }}
              okText="Yes"
              cancelText="No"
            >
              <Button icon={<EyeOutlined />} />
            </Popconfirm>}
          </>
        );
      },
    },
  ];

  if (isLoading) {
    return <Spin size="large" />;
  }

  return (
    <>
      <Radio.Group className="user-type"
          options={[
            { label: 'Consumer', value: '2' },
            { label: 'Designer', value: '3' },
          ]}
        onChange={(e) => {
          setUserType(e.target.value);
        }}
          value={userType}
          optionType="button"
        buttonStyle="solid"
        defaultValue={userType}
        />
    <Table
      dataSource={users}
      columns={columns}
      pagination={{
        total: users.length,
        pageSize: 10,
        showSizeChanger: false,
      }}
      />
      </>
  );
};
