import React, { useEffect, useState } from "react";
import { Button, Layout, Menu} from "antd";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  SettingOutlined,
  UserOutlined
} from "@ant-design/icons";
import "./style.css";
import { me } from "../../services/auth";
import { useHistory } from "react-router";
import { Settings } from "../../elements/settings";
import { Users } from "../../elements/users";

const { Header, Sider } = Layout;
export const Dashboard = () => {
  var [collapsed, setCollapsed] = useState(true);
  var [user, setUser] = useState(null);
  var [activeTab, setActiveTab] = useState("1");
  var { push } = useHistory();
  useEffect(() => {
    me().then((res) => {
      setUser(res);
    });
  }, []);
  return (
    <Layout className="dashboard-container">
      <Sider
        trigger={null}
        collapsible
        collapsed={collapsed}
        className="side-bar"
      >
        <div className="logo" />
        <Menu theme="dark" mode="inline" defaultSelectedKeys={[activeTab]}>
          <Menu.Item key="1" icon={<SettingOutlined />} onClick={() => {
            setActiveTab("1");
          }}>
            Settings
          </Menu.Item>
          <Menu.Item key="2" icon={<UserOutlined />} onClick={() => {
            setActiveTab("2");
          }}>
            Users
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout className="site-layout">
        <Header className="site-layout-background" style={{ padding: 0 }}>
          {collapsed ? (
            <MenuUnfoldOutlined
              className="trigger"
              onClick={() => {
                setCollapsed(!collapsed);
              }}
            />
          ) : (
            <MenuFoldOutlined
              className="trigger"
              onClick={() => {
                setCollapsed(!collapsed);
              }}
            />
          )}
          <Button
            type="primary"
            className="logout-button"
            onClick={() => {
              localStorage.removeItem("token");
              push("/login");
            }}
          >
            Logout {user ? user.name : ""}
          </Button>
        </Header>
        {activeTab === "1" && <Settings />}
        {activeTab === "2" && <Users />}
      </Layout>
    </Layout>
  );
};
