import { Spin, Table } from "antd";
import { useEffect, useState } from "react";
import { getLocations } from "../../services/location";

export const Locations = () => {
  var [locations, setLocations] = useState([]);
  var [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    getLocations().then((res) => {
      res = res.map(loc => {
        loc.key = loc.id;
        return loc;
      });
      setLocations(res);
      setIsLoading(false);
    });
  }, []);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
    },
  ];

  if (isLoading) {
    return <Spin size="large" />;
  }

  return <Table dataSource={locations} columns={columns} pagination={
    {
      total: locations.length,
      pageSize: 10,
      showSizeChanger:false
    }
  }/>;
};
