export const getSpecialities = () => {
  return fetch(process.env.REACT_APP_API_URL + "/api/specialities", {
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    referrerPolicy: "no-referrer",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  }).then((response) => response.json());
};

export const getSpecialitySubs = (specialityId) => {
  return fetch(process.env.REACT_APP_API_URL + "/api/speciality/subs/" + specialityId, {
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    referrerPolicy: "no-referrer",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  }).then((response) => response.json());
};

export const createSpeciality = (data) => {
  return fetch(process.env.REACT_APP_API_URL + "/api/speciality", {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + localStorage.getItem("token")
    },
    referrerPolicy: "no-referrer",
    body: JSON.stringify(data),
  }).then((res) => res.json());
};

export const updateSpeciality = (data) => {
  return fetch(process.env.REACT_APP_API_URL + "/api/speciality/"+data.id, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + localStorage.getItem("token")
    },
    referrerPolicy: "no-referrer",
    body: JSON.stringify(data),
  }).then((res) => res.json());
};

export const updateSubSpeciality = (data) => {
  return fetch(process.env.REACT_APP_API_URL + "/api/subspeciality/"+data.id, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + localStorage.getItem("token")
    },
    referrerPolicy: "no-referrer",
    body: JSON.stringify(data),
  }).then((res) => res.json());
};

export const createSpecialitySub = (data) => {
  return fetch(process.env.REACT_APP_API_URL + "/api/subspeciality", {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + localStorage.getItem("token")
    },
    referrerPolicy: "no-referrer",
    body: JSON.stringify(data),
  }).then((res) => res.json());
};

export const deleteSpeciality = (id) => {
  return fetch(process.env.REACT_APP_API_URL + "/api/speciality/"+id, {
    method: "DELETE",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + localStorage.getItem("token")
    },
    referrerPolicy: "no-referrer",
    body: JSON.stringify(id),
  }).then((res) => res.json());
};

export const deleteSpecialitySub = (id) => {
  return fetch(process.env.REACT_APP_API_URL + "/api/subspeciality/"+id, {
    method: "DELETE",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + localStorage.getItem("token")
    },
    referrerPolicy: "no-referrer",
    body: JSON.stringify(id),
  }).then((res) => res.json());
};
