import {
  Button,
  Table,
  Popconfirm,
  Drawer,
  message,
  Spin,
  PageHeader,
} from "antd";
import { useEffect, useState } from "react";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import "./style.css";
import { deleteSpecialitySub, getSpecialitySubs } from "../../services/specialities";
import { SpecialitySubForm } from "../specialitySubForm";

export const SpecialitySubs = ({ specialityId, specialityName }) => {
  var [specialities, setSpecialities] = useState([]);
  var [isSpecialityFormVisible, setSpecialityFormVisible] = useState(false);
  var [isLoading, setIsLoading] = useState(true);
  var [editingSubSpeciality, setEditingSubSpeciality] = useState({});
  const loadSpecialities = () => {
    setIsLoading(true);
    getSpecialitySubs(specialityId)
      .then((res) => {
        res = res.map(loc => {
          loc.key = loc.id;
          return loc;
        });
        setSpecialities(res);
        setIsLoading(false);
      })
      .catch((e) => {
        message.error("Failed to load sub categories!");
        setIsLoading(false);
      });
  };
  useEffect(() => {
    loadSpecialities();
  }, []);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "action",
      render: (id, row) => {
        return (
          <>
            <Popconfirm
              title="Are you sure to delete this sub category?"
              onConfirm={() => {
                setIsLoading(true);
                deleteSpecialitySub(id).then((res) => {
                  if (res.error) {
                    message.error(
                      "Failed to delete the sub category. Please try again ..."
                    );
                    setIsLoading(false);
                    return;
                  }
                  message.success(
                    "Sub category deleted Deleted. Reloading the list ..."
                  );
                  loadSpecialities();
                });
              }}
              onCancel={() => {}}
              okText="Yes"
              cancelText="No"
            >
              <Button icon={<DeleteOutlined />} />
            </Popconfirm>
            <Button icon={<EditOutlined />} onClick={() => {
              setEditingSubSpeciality(row);
              setSpecialityFormVisible(true);
            }}/>
          </>
        );
      },
    },
  ];

  if (isLoading) {
    return <Spin size="large" />;
  }

  return (
    <>
      <PageHeader
        title={"Sub Categories of " + specialityName}
        extra={<Button
          type="primary"
          className="add-product-category-button"
          onClick={() => {
            setEditingSubSpeciality({});
            setSpecialityFormVisible(true);
          }}
        >
          Add New
        </Button>}
      />
      
      <Table
        dataSource={specialities}
        columns={columns}
        pagination={{
          total: specialities.length,
          pageSize: 10,
          showSizeChanger: false,
        }}
      />
      <Drawer
        title="Add/Edit Sub Speciality"
        placement="right"
        width={window.innerWidth / 4}
        onClose={() => {
          setEditingSubSpeciality({});
          setSpecialityFormVisible(false);
        }}
        visible={isSpecialityFormVisible}
      >
        {isSpecialityFormVisible && <SpecialitySubForm
          onDone={() => {
            setEditingSubSpeciality({});
            setSpecialityFormVisible(false);
            loadSpecialities();
          }}
          specialityId={specialityId}
          speciality={editingSubSpeciality} />}
      </Drawer>
    </>
  );
};
