export const getLocations = () => {
  return fetch(process.env.REACT_APP_API_URL + "/api/locations", {
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    referrerPolicy: "no-referrer",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  }).then((response) => response.json());
};
