export const getCategories = () => {
  return fetch(process.env.REACT_APP_API_URL + "/api/product-categories", {
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    referrerPolicy: "no-referrer",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  }).then((response) => response.json());
};

export const createProductCategory = (data) => {
  return fetch(process.env.REACT_APP_API_URL + "/api/product-category", {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + localStorage.getItem("token")
    },
    referrerPolicy: "no-referrer",
    body: JSON.stringify(data),
  }).then((res) => res.json());
};

export const updateProductCategory = (data) => {
  return fetch(process.env.REACT_APP_API_URL + "/api/product-category/"+data.id, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + localStorage.getItem("token")
    },
    referrerPolicy: "no-referrer",
    body: JSON.stringify(data),
  }).then((res) => res.json());
};

export const deleteProductCategory = (id) => {
  return fetch(process.env.REACT_APP_API_URL + "/api/product-category/"+id, {
    method: "DELETE",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + localStorage.getItem("token")
    },
    referrerPolicy: "no-referrer",
    body: JSON.stringify(id),
  }).then((res) => res.json());
};
