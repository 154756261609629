import { Col, PageHeader, Row } from "antd";
import { Content } from "antd/lib/layout/layout";
import { Brands } from "../brands";
import { Locations } from "../locations";
import { Materials } from "../materials";
import { ProductCategories } from "../productCategories";
import { Specialities } from "../specialities";

export const Settings = () => {
  return (
    <Content
      className="site-layout-background app-contents"
      style={{
        margin: "24px 16px",
        padding: 24,
        minHeight: 280,
      }}
    >
      <Row>
        <Col span={8} className="settings-column">
          <PageHeader title="Product Categories" />
          <ProductCategories />
        </Col>
        <Col span={8} className="settings-column">
          <PageHeader title="Specialities" />
          <Specialities />
        </Col>
        <Col span={8} className="settings-column">
          <PageHeader title="Materials" />
          <Materials />
        </Col>
      </Row>
      <Row>
        <Col span={8} className="settings-column">
          <PageHeader title="Consumer/Customer Location" />
          <Locations />
        </Col>
        <Col span={8} className="settings-column">
          <PageHeader title="Brands" />
          <Brands />
        </Col>
      </Row>
    </Content>
  );
};
