import { message,Form, Input,Button, Spin } from "antd";
import { useState } from "react";
import { createSpeciality, updateSpeciality } from "../../services/specialities";

export const SpecialityForm = ({onDone, speciality={}}) => {
    var [isLoading, setIsLoading] = useState(false);
    if (isLoading) {
        return <Spin size="large" />
    }
    
    return <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        initialValues={{}}
        onFinish={(value) => {
            setIsLoading(true);
            if (speciality.id === undefined || speciality.id === 0) {
                createSpeciality(value).then(res => {
                    if (res.error) {
                        message.error(res.error);
                        setIsLoading(false);
                        return;
                    }
                    setIsLoading(false);
                    onDone();
                }).catch(e => {
                    
                    setIsLoading(false);
                    message.error("Could not save the speciality. Try again...");
                });
            }
            if (speciality.id > 0) {
                var updateObj = Object.assign({}, value);
                updateObj.id = speciality.id
                updateSpeciality(updateObj).then(res => {
                    if (res.error || res.exception) {
                        message.error(res.error);
                        setIsLoading(false);
                        return;
                    }
                    setIsLoading(false);
                    onDone();
                }).catch(e => {
                    
                    setIsLoading(false);
                    message.error("Could not save the speciality. Try again...");
                });
            }
            
        }}
        onFinishFailed={() => {
            message.error("Please fix the validation issue!");
        }}
        autoComplete="off"
    >
        <Form.Item
            label="Name"
            name="name"
            rules={[{ required: true, message: 'Please provide the speciality!' }]}
            initialValue={speciality.name?speciality.name:''}
        >
            <Input />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button type="primary" htmlType="submit">
                Save
            </Button>
        </Form.Item>
    </Form>;
};