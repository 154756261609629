import { Col, PageHeader, Row } from "antd";
import { Content } from "antd/lib/layout/layout";
import { UserList } from "../userList";

export const Users = () => {
  return (
    <Content
      className="site-layout-background app-contents"
      style={{
        margin: "24px 16px",
        padding: 24,
        minHeight: 280,
      }}
    >
      <Row>
        <Col span={24} className="settings-column">
          <PageHeader title="Users" />
          <UserList />
        </Col>
      </Row>
    </Content>
  );
};
