import { message,Form, Input,Button, Spin } from "antd";
import { useState } from "react";
import { createProductCategory, updateProductCategory } from "../../services/productCategories";

export const ProductCategoryForm = ({ onDone, productCategory = {}}) => {
    var [isLoading, setIsLoading] = useState(false);
    if (isLoading) {
        return <Spin size="large" />
    }
    return <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        initialValues={{ remember: true }}
        onFinish={(value) => {
            setIsLoading(true);
            if (productCategory.id === undefined || productCategory.id === 0) {
                createProductCategory(value).then(res => {
                    if (res.error) {
                        message.error(res.error);
                        setIsLoading(false);
                        return;
                    }
                    setIsLoading(false);
                    onDone();
                }).catch(e => {
                    
                    setIsLoading(false);
                    message.error("Could not save the product category. Try again...");
                });
            }
            if (productCategory.id > 0) {
                var updateObj = Object.assign({}, value);
                updateObj.id = productCategory.id
                updateProductCategory(updateObj).then(res => {
                    if (res.error || res.exception) {
                        message.error(res.error);
                        setIsLoading(false);
                        return;
                    }
                    setIsLoading(false);
                    onDone();
                }).catch(e => {
                    
                    setIsLoading(false);
                    message.error("Could not update the product category. Try again...");
                });
            }
        }}
        onFinishFailed={() => {
            message.error("Please fix the validation issue!");
        }}
        autoComplete="off"
    >
        <Form.Item
            label="Name"
            name="name"
            rules={[{ required: true, message: 'Please provide the category name!' }]}
            initialValue={productCategory.name?productCategory.name:''}
        >
            <Input />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button type="primary" htmlType="submit">
                Save
            </Button>
        </Form.Item>
    </Form>;
};